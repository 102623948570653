/* 
 * @Author: 曹俊杰 
 * @Date: 2020-09-07 14:16:26
 * @Module: 主播入会审批列表
 */
<template>
  <div>
    <div class="header">
      <h6>主播入会审批</h6>
    </div>
    <el-card style="margin-top:20px">
      <el-row :gutter="20">
        <el-form :inline="true" :size="'small'" :model="formInline" class="demo-form-inline">
          <el-col :span="6">
            <el-form-item label="昵称:">
              <el-input v-model="formInline.tick_name" placeholder="请输入要查询的昵称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="真实姓名:">
              <el-input v-model="formInline.name" placeholder="请输入要查询的真实姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="探探号:">
              <el-input v-model="formInline.tick_account" placeholder="请输入要查询的探探号"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="req_list(true)">搜索</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="alert-style" @click="help">
        <el-alert title="点击查看帮助" type="warning" show-icon></el-alert>
      </div>
      <tablePage
        :tableData="tableData"
        :currentPage="formInline.page"
        :height="tableHeight"
        @current-change="currentChange"
        v-loading="loading"
        :total="total"
        id="table"
        ref="tableList"
      >
        <el-table-column prop="nick_name" min-width="100" label="昵称"></el-table-column>
        <el-table-column prop="tick_account" min-width="100" label="探探号"></el-table-column>
        <el-table-column prop="name" min-width="100" label="真实姓名"></el-table-column>
        <el-table-column prop="created_at" min-width="100" label="申请时间"></el-table-column>
        <el-table-column label="操作" min-width="100">
          <template slot-scope="scope">
            <router-link :to="'/anchor/enrollment?anchor_id='+scope.row.anchor_id">
              <el-button type="text">通过</el-button>
            </router-link>
          </template>
        </el-table-column>
      </tablePage>
    </el-card>
  </div>
</template>
 <script>
//  document.body.clientHeight
import tablePage from "components/tablePage";
// import belonger from "components/belonger";
import tableHeight from "mixins/tableHeight";
import api from "@/api";
export default {
  data() {
    return {
      loading: true,
      formInline: {
        tick_name: null, // 昵称
        name: null, //真实姓名
        tick_account: null, //探探号
        page: 1,
      },
      tableData: [],
      total: 10,
      tableHeight: 1000,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  components: {
    tablePage,
  },
  mixins: [tableHeight],
  created() {
    this.req_list(true);
  },
  mounted() {},
  methods: {
    async _initState(params = {}) {
      this.loading = true;
      const { status_code, meta, message } = await api.inviteListApi(params);
      if (status_code === 200) {
        const {
          pagination: { total },
        } = meta;
        this.total = total;
        this.tableData = message;
        this.loading = false;
      } else if (status_code == 422) {
        Object.keys(message).forEach((item) => {
          this.$message.error({ message: message[item] });
        });
      }
    },
    currentChange(page) {
      this.formInline.page = page;
      this.req_list();
    },
    // 请求列表
    req_list(refresh) {
      if (refresh) this.formInline.page = 1;
      if (!this.formInline.broadcast_date)
        this.formInline.broadcast_date = this.datePickerDefaultValue;
      this._initState(this.formInline);
    },
    //查看帮助
    help() {
      let url = "https://shimo.im/docs/VYG3rp3p9ktPXCcd/";
      window.open(url);
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
@import "./style.less";
.alert-style {
  margin-bottom: 9px;
  & /deep/ .el-alert--warning {
    color: #60c3f7;
    background: rgba(96, 195, 247, 0.1);
    font-size: 13px;
  }
}
</style>